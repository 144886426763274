const { post } = require("jquery")

$(document).on('turbolinks:load', function () {
	new WOW().init()
	
	var $window = $(window),
			$body = $('html, body'),
			$header = $('.header')

	// Languages switcher
	var langDropdown = $('.lang__dropdown'),
		langButton = $('.lang__item--button')

	langButton.on('click', function (e) {
		langDropdown.toggleClass('active')
		e.stopPropagation();
	})

	$body.on('click', function () {
		langDropdown.removeClass('active')
	})
	// END


	// Lang switcher
	var langButton = $('.lang__item'),
			langDropdown = $('.lang__in')
	
	langButton.click(function(){
		langDropdown.toggleClass('lang__in--active')
		langButton.toggleClass('lang__item--active')
	})
	// END


	// Hamburger
	var toggle = $('.hamburger')
	var menu = $('.sidebar-menu')
	var active = 'sidebar-menu--active'

	menu.on('click', function() {
		menu.removeClass(active)
	})

	toggle.on('click', function() {
		menu.toggleClass(active)
	})

	$('.nav__toggle').click(function(){
		menu.removeClass(active)
	})

	menu.find('.nav').on('click', function(e) {
		e.stopPropagation()
	})
	// END


	// Carousel
	var $sliders = $('.swiper-slider')

	$sliders.each(function(_, slider) {
		var galleryThumbs = new Swiper($(slider).next('.swiper-nav').eq(0), {
			lazy: true,
			spaceBetween: 5,
      slidesPerView: 6,
      watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				320:{
					slidesPerView: 3,
				},
				480:{
					slidesPerView: 4,
				},
				1200:{
					slidesPerView: 6,
				}
			}
		})
		
		new Swiper(slider, {
			lazy: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			thumbs: {
				swiper: galleryThumbs
			}
		})
	})

	var $carousels = $('.swiper-carousel')

	$carousels.each(function(_, carousel) {
		new Swiper(carousel, {
			lazy: true,
			breakpoints: {
				320:{
					slidesPerView: 1,
				},
				480:{
					slidesPerView: 2,
				},
				1200:{
					slidesPerView: 3,
				}
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			}
		})
	})
	// END


	// Init PhotoSwipe on carousel
	var galleryItem = $('.swiper-carousel')
	if (galleryItem.length > 0) {
		var slide = galleryItem.find('.swiper-slide')

		slide.on('click', function() {
			var options = {
				history: false,
				galleryUID: false,
				index: $(this).index(),
				shareEl: false,
				clickToCloseNonZoomable: false
			}
			var gallery = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, galleryItem.data('photoswipe'), options);
			gallery.init();
		})
	}
	// END

	$('.questions__button').click(function(){
		if($(this).parents('.questions__accordeon').hasClass('questions__accordeon--active')){
		  $(this).parents('.questions__accordeon').removeClass('questions__accordeon--active')
		  $(this).parents('.questions__accordeon').find('iframe').attr('src', '')
		  $(this).parents('.questions__accordeon').find('.questions__bottom').slideUp()
		}else {
		  $(this).parents('.questions__accordeon').addClass('questions__accordeon--active')
		  $(this).parents('.questions__accordeon').find('iframe').attr('src', $(this).parents('.questions__accordeon').attr('data-src'))
		  $(this).parents('.questions__accordeon').find('.questions__bottom').slideDown()
		}
	  })
	  var content = $('.questions__content')
	  var tabs = $('.questions__item')
	
	  content.not(":first").hide();
	  tabs.click(function(){
		tabs.removeClass("questions__item--active").eq($(this).index()).addClass("questions__item--active");
		content.hide().eq($(this).index()).fadeIn()
	  }).eq(0).addClass("questions__item--active")


	// Add class to header
	$window.scroll(function(){
		if ($header.hasClass('header--main')) {
			$header.toggleClass('header--fixed', $(this).scrollTop() > 0)
		}
	})
	// END


	// Scroll to block
	$('.headline__submit').click(function(e){
		var id = $(this).attr('href'),
				top = $(id).offset().top - $header.height()

		$body.animate({ scrollTop: top }, 500)

		e.preventDefault()
	})
	// END

	$('.anchor').click(function(e){
		var id = $(this).attr('href'),
			top = $(id).offset().top - $header.height()

		$body.animate({ scrollTop: top }, 500)

		e.preventDefault()
	})


	// Select full site url
	$('.social-links__remark').on('click', function() {
		$(this).select()
	})
	// END


	// Phone field mask
	var phoneField = $('.field--number')
	phoneField.on('input', function() {
		var $this = $(this)
		$this.val($this.val().replace(/[^\+\d]/g, ''))
	})

	phoneField.on('click', function() {
		var length = $(this).val().length
		$(this)[0].setSelectionRange(length, length)
	})

	phoneField
		.on('focus', function() {
			var $this = $(this)
			if ($this.val().length !== 13) {
				$(this).val('+998')
			}
		})
		.on('blur', function() {
			var $this = $(this)
			if ($this.val().length !== 13) {
				$this.val('')
			}
		})
	// END


	// Management page content
	var person = $('.person');

	$.each(person, function(_, el) {
		var expand = $(el).find('.person__button--expand'),
				collapse = $(el).find('.person__button--collapse'),
				detail = $(el).find('.person__detail')

		expand.on('click', function() {
			expand.hide()
			detail.slideDown(500)
		})

		collapse.on('click', function() {
			expand.show()
			detail.slideUp(500)
		})
	})

	$('.form--small').on('change', function(){
		var firstName = $('.field--first-name').val()
		var lastName = $('.field--last-name').val()
		var middleName = $('.field--middle-name').val()
		var textarea = $('.form__last').text()
		var textarea2 = $('.form__first').text()
		var ruValue = textarea.startsWith('Я') || textarea2.startsWith('Я')
		var uzValue = textarea.startsWith('Men') || textarea2.startsWith('Men')
		if(ruValue){
			$('.form__first').text(`Я, ${lastName} ${firstName} ${middleName}`)
			$('.form__last').text(textarea.replace('Я, ___', ''))
		}else if (uzValue) {
			$('.form__first').text(`Men, ${lastName} ${firstName} ${middleName}`)
			$('.form__last').text(textarea.replace('Men, ___', ''))
		}
		
		if(!firstName.trim() && !lastName.trim() && !middleName.trim() && ruValue){
			$('.form__first').html('')
			var arr = textarea.split()
			arr.unshift('Я, ___')
			var newArr = arr.join('')
			$('.form__last').text(newArr)
		  } else if (!firstName.trim() && !lastName.trim() && !middleName.trim() && uzValue){
			$('.form__first').html('')
			var arr = textarea.split()
			arr.unshift('Men, ___')
			var newArr = arr.join('')
			$('.form__last').text(newArr)
		  }
	})

	// $('#container').html("<%= j(render 'nav_js')%>");
	// Pagy.init(document.getElementById('container'));
		
	// END

	var pageCount = 2
	var resultCount = 0
	getData(1)

	function getData (count){
		var pathNameUz = window.location.pathname.startsWith('/oz/')
		var pathNameRu = window.location.pathname.startsWith('/ru/')
		var pathname = ''
		if(pathNameUz){
			pathname = 'oz'
		}else if (pathNameRu){
			pathname = 'ru'
		}
		$.ajax({
			url: '/' + pathname + '/news/aoka-gender/votes' + '?page=' + count,
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json',
			},
			// data: JSON.stringify(data[name]),
			success: function (result) {
			  var pagesCount = Math.ceil(result?.pagy?.count / 10)
			  resultCount = pagesCount
			  if(pagesCount > 1){
				  $('#show-more').addClass('btn--show')
			  }
			  if(result.data.length > 0){
				$('.paper').addClass('paper--show')
			  }
			  var content = ''
			  var content = result?.data?.map(function(i){
				return (
					'<tr><td>' + i?.full_name + '</td>' + '<td>' + i?.comment + '</td>' + '</tr>'
				)
			  })
			  $('.table__body').append(content)
			},
			error: function (xhr, status, error) {
		
			},
			// complete: function () {
			//   $this.find('.form__submit').removeAttr('disabled');
			//   grecaptcha.reset();
			// },
		});
	}

	$('#show-more').click(function(){
		var count = pageCount++
		if(count > resultCount) return
		if(count === resultCount) {
			$(this).addClass('btn--disabled')
		}

		getData(count)
	})
})